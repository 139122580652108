import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyCHXu92H6srfopgBWXSsEUfxRIjurW86r8',
  authDomain: 'elha-encuestas.firebaseapp.com',
  projectId: 'elha-encuestas',
  storageBucket: 'elha-encuestas.appspot.com',
  messagingSenderId: '1031915552117',
  appId: '1:1031915552117:web:a23bc461d9a78f1bb1a73c',
  measurementId: 'G-QHBBYGKYNH',
};
firebase.initializeApp(firebaseConfig);

// utils
const db = firebase.firestore();
const auth = firebase.auth();
const { firestore } = firebase;

// collection references
const usersCollection = db.collection('users');

const companiesCollection = db.collection('companies');

const surveysCollection = db.collection('surveys');

const sectionsCollection = (surveyId) => surveysCollection.doc(surveyId).collection('sections');

const answersCollection = (surveyId) => surveysCollection.doc(surveyId).collection('answers');

const filtersCollection = (surveyId) => surveysCollection.doc(surveyId).collection('filters');

const questionsCollection = (surveyId) => surveysCollection.doc(surveyId).collection('questions');

const scaleCollection = (surveyId) => surveysCollection.doc(surveyId).collection('scale');

// export utils/refs
export {
  db,
  auth,
  firestore,
  usersCollection,
  companiesCollection,
  surveysCollection,
  sectionsCollection,
  answersCollection,
  filtersCollection,
  questionsCollection,
  scaleCollection,
};
